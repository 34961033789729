@use "sass:math";
@use "./card";

#aila-other-projects {
    li {
        display: block;
    }

    h2,
    h3 {
        a::after {
            content: "";
            display: inline-block;
            width: 1em;
            height: 1em;
            vertical-align: text-top;
            margin-left: 0.5em;
            background-image: url("../images/icon-open-link.svg");
            background-size: contain;
        }
    }

    iframe.pdf {
        $proportions: math.div(8.5, 11);
        $height: 600px;

        width: $height * $proportions;
        height: $height;
    }

    details > div {
        background: #efefef;

        // The 0.1px hack is to allow margin at the bottom of the contents to render. Otherwise it
        // collapses with no perceive padding
        padding: 2em 2em 0.1px;
    }

    #aila-75th-video iframe {
        $height: 15em;

        height: $height;
        width: math.div($height, 9) * 16; // 16:9 aspect ratio
        max-width: 100%;
    }

    summary {
        display: block;
        text-align: center;
        cursor: pointer;
        padding-bottom: 1em;
    }

    #aila-brochures {
        h2 {
            text-align: left;
        }
    }

    #aila-brochures,
    #aila-presidents-letter {
        text-align: center;

        & > a {
            @include card.button;

            display: none;
        }

        @media (width <= 600px) {
            iframe {
                display: none;
            }

            a {
                display: inline-block;
            }
        }
    }
}
