@use "./braces";
@use "./close-button";
@use "./selectors";
@use "./locations";

$mobile-menu-max-width: 650px;

nav {
    display: flex;
    align-items: center;

    input[type="checkbox"] {
        @extend %close-button;

        display: none;
        background-image: url("../images/icon-menu.svg");

        &:checked {
            background-image: url("../images/icon-close.svg");
        }
    }

    @include selectors.on-home {
        @media (max-width: $mobile-menu-max-width) {
            align-items: start;
            justify-content: space-between;

            ul {
                display: none;
            }

            input[type="checkbox"] {
                display: initial;
            }

            input[type="checkbox"]:checked {
                z-index: 1;
            }

            input[type="checkbox"]:checked + ul {
                position: fixed;
                top: 0;
                left: 0;
                display: flex;
                width: 100%;
                height: 100%;
                flex-direction: column;
                align-items: flex-end;
                padding: 2em;
                padding-top: 7em;
                background: linear-gradient(
                    270deg,
                    white 0%,
                    white 20em,
                    rgb(255 255 255 / 0%) 100%
                );
            }
        }
    }

    & > a {
        &::before,
        &::after {
            content: "";
        }
    }

    img {
        height: 13em;
    }

    & > [tabindex="-1"] {
        display: none;
    }

    ul {
        margin: 0;
        line-height: 3em;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    li {
        display: inline;
        margin: 0 0.3em;
        font-size: 1.5em;
        white-space: nowrap;

        a {
            @extend %braces;
        }
    }
}

main > div {
    display: none;
}

body[data-location=""] #home {
    display: initial;
}

@each $location in locations.$locations {
    body[data-location="#{$location}"] ##{$location} {
        display: block;
    }
}
