#other-projects {
    ul {
        margin-left: 3em;
        list-style: none;
    }

    li {
        margin-bottom: 1em;
    }
}
