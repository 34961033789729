@use "./fonts";
@use "./braces";
@use "./nav";

body[data-location=""] main #home {
    display: block;
}

// stylelint-disable-next-line no-descending-specificity
#home {
    text-align: center;

    h2 {
        width: 80%;
        margin: 1em auto 0;
        font-weight: bold;

        @media (max-width: nav.$mobile-menu-max-width) {
            width: initial;
            font-size: 1.2em;
        }

        @media (width <= 475px) {
            font-size: 1.1em;
        }
    }

    #writing-styles {
        @include fonts.font-galada;

        font-size: 1.5em;
        line-height: 2;

        @media (max-width: nav.$mobile-menu-max-width) {
            font-size: 6vw;
        }

        @media (width <= 475px) {
            font-size: 5vw;
        }
    }

    li {
        @extend %braces;

        display: block;
    }
}
