@use "./colors";
@use "./fonts";
@use "./dimens";

$body-padding: 3em;

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    @include fonts.font-nixie;

    width: 100%;
    max-width: 1030px;
    height: 100%;
    padding: $body-padding;
    margin: auto;
    background: white;
    font-size: 20px;
    line-height: dimens.$line-height;
    overflow-x: hidden;

    @media (width <= 800px) {
        font-size: 16px;
    }

    &.disable-scrolling {
        overflow: hidden;
    }
}

input,
textarea {
    font-size: 20px;
    line-height: dimens.$line-height;

    @media (width <= 800px) {
        font-size: 16px;
    }
}

input,
button,
textarea {
    @include fonts.font-nixie;

    background: colors.$input-background;
}

p {
    margin-bottom: 2em;
    font-weight: bold;
}

article {
    margin-bottom: 2em;
}

address {
    font-style: normal;
}

a {
    color: colors.$content-color;
    font-weight: bold;
    text-decoration: none;
}

h2 {
    margin-top: 1em;
    margin-bottom: 0.5em;
}

h3 {
    line-height: 2;
}

blockquote {
    margin-left: 2em;

    br {
        line-height: 2em;
    }
}

main {
    margin-top: 2em;

    img,
    iframe {
        // Re-enable loading post CSS load
        display: initial;
    }
}

.noscroll {
    overflow: hidden;
}
