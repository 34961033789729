@forward "./contact";
@forward "./other-projects";
@forward "./recommendations";
@forward "./resume";
@forward "./aila-emails";
@forward "./aila-flyers";
@forward "./aila-other-projects";
@forward "./tov";

body:not([data-location=""]) {
    #home {
        display: none;
    }

    nav {
        @media (width <= 700px) {
            flex-direction: column;
        }

        img {
            height: 6em;
        }

        ul {
            margin-left: 1em;
            line-height: 1.5;

            // Change back from flex but still align center
            display: initial;
            text-align: center;
        }

        li {
            font-size: 1em;
        }
    }

    main {
        text-align: left;
    }

    h1 {
        margin-bottom: 0.5em;
    }

    article {
        ul,
        ol {
            margin-left: 1em;
        }
    }
}
