@use "./fonts";

#editorial-and-brand-copy {
    img {
        max-width: 100%;
    }

    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    li {
        @include fonts.font-founders-grotesk;

        position: relative;
        padding: 3em;
        margin-bottom: 3em;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: white;
        list-style: none;
        transition: all cubic-bezier(0.94, 0.02, 0.19, 1.38) 700ms;

        &:hover,
        &:focus {
            transform: scale(1.05);
            transition-delay: 300ms;
        }

        h2,
        p {
            text-shadow: 0 0 0.2em black;
        }

        h2 {
            width: 60%;
            font-size: 3.5em;
            line-height: 1em;
            text-transform: uppercase;
        }

        p {
            width: 80%;
            margin: 0;
        }

        @media (width <= 1300px) {
            width: 100vw;
            padding: 6em;
        }

        @media (width <= 600px) {
            padding: 3em;

            p {
                width: 100%;
            }
        }
    }
}
