@use "./colors";
@use "./card";

// Does not match ordering that makes sense given page layout
// stylelint-disable no-descending-specificity

$break-point-width: 500px;

@mixin right-pipe {
    padding-right: 0.5em;
    border-right: 0.05em solid colors.$content-color;
    margin-right: 0.5em;
}

@mixin arrow-list {
    list-style: circle inside;

    li {
        padding-left: 1em;
    }
}

@mixin normalize-header {
    font-weight: normal;
}

#resume h1 {
    flex-grow: 1;

    @media (min-width: $break-point-width) {
        margin-bottom: 0;
    }
}

#resume-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
}

#resume-download {
    @include card.button;
}

#resume-linkedin {
    line-height: 1em;

    img {
        height: 3em;
    }
}

#resume-wrapper {
    h2,
    h3 {
        text-align: center;
    }

    h2,
    h3,
    h4 {
        text-transform: uppercase;

        &::first-letter,
        .resume-header-word {
            font-size: 1.5em;
        }
    }

    ul,
    ol {
        list-style: none;
    }

    hr {
        height: 0.1em;
        border: none;
        margin: 1em 0;
        background: colors.$content-color;
    }

    #resume-header {
        text-align: center;
    }

    #resume-summary {
        text-align: center;
    }

    #resume-summary-competencies {
        font-style: italic;

        ul {
            display: block;
            font-style: normal;

            li {
                display: inline;

                @include right-pipe;

                &:last-child {
                    border: none;
                }
            }
        }
    }

    #resume-professional-experience {
        ol {
            text-align: left;

            > li {
                margin-bottom: 1em;
            }

            ul {
                @include arrow-list;
            }
        }
    }

    .resume-experience-header {
        margin-bottom: 0.5em;
        text-transform: uppercase;

        &::after {
            display: block;
            clear: both;
            content: "";
        }

        & > :first-child {
            @include right-pipe;
        }

        & > :nth-child(2) {
            margin-right: 1em;
            font-weight: bold;
        }

        & > :last-child {
            float: right;
        }
    }

    #resume-education {
        h4 {
            @include normalize-header;

            & + * {
                font-weight: bold;
            }
        }

        ul {
            > li {
                margin-bottom: 1em;
            }

            ul {
                @include arrow-list;
            }
        }
    }

    #resume-awards {
        h4 {
            @include normalize-header;
        }

        ul ul {
            @include arrow-list;
        }
    }
}

@media (max-width: $break-point-width) {
    #resume-title {
        flex-direction: column;
        margin-bottom: 1em;
    }

    #resume-download {
        padding: 1em;
        margin-bottom: 1em;
    }

    #resume-linkedin {
        text-align: center;
    }
}
