#recommendations {
    li {
        border: 0.05em solid #ddd;
        margin-bottom: 3em;
        list-style: none;

        blockquote {
            & > :first-child::before {
                content: "“";
            }

            & > :last-child::after {
                content: "”";
            }

            & > :first-child::before,
            & > :last-child::after {
                display: inline;
                color: #9d9d9d;
                font-size: 4em;
                line-height: 0;
                vertical-align: bottom;
            }
        }

        p {
            margin-bottom: 1em;
        }

        > * {
            padding: 1em;
        }

        > :first-child {
            width: 20em;
            max-width: 100%;
            margin: 0 1em 1em 0;
            background: #ddd;
            float: left;

            :last-child {
                font-style: italic;
            }

            @media (width <= 600px) {
                width: 100%;
            }
        }
    }
}
