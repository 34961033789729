@use "./colors";
@use "./fonts";

%braces {
    color: colors.$content-color;
    font-weight: bold;
    text-decoration: none;

    &::before,
    &::after {
        @include fonts.font-nixie;

        display: inline-block;
        transition: all ease-out 300ms;
    }

    &::before {
        content: "{";
    }

    &::after {
        content: "}";
    }

    &:hover,
    &:focus {
        &::before {
            transform: translateX(-0.5em);
        }

        &::after {
            transform: translateX(0.5em);
        }
    }
}
